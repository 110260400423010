import React, { useState, useEffect } from 'react';
import { TextControl } from '../InputControls';
import { makeRequest } from '../../Utils';
import { messageState } from '../../atoms';
import { useSetRecoilState } from 'recoil';

export default function GiftCardCheck({ giftCardBalance, setGiftCardBalance, total }) {
  const [checking, setChecking] = useState(false);
  const [id, setId] = useState('');
  const setMessage = useSetRecoilState(messageState)

  const checkBalance = async () => {
    let data = await makeRequest(`gift-card?id=${id}`);
    if (data.balance && data.balance > 0) {
      setGiftCardBalance(data.balance);
      setChecking(false);
    } else if (data.balance && data.balance === 0) {
      setMessage()
    } else {
      setMessage()
    }
  }

  return (
    <div>
      {!giftCardBalance && !checking &&
        <div><button onClick={() => { setChecking(true) }} className='buttonSecondary buttonSecondary-red'>Have a Gift Card?</button></div>
      }
      {checking && giftCardBalance === 0 &&
        <>
          <TextControl label='Enter your gift card code' value={id} change={setId} />
          <button className='buttonPrimary' onClick={checkBalance}>Check</button>
        </>
      }
      {!checking && giftCardBalance > 0 &&
        <div>Your gift card has a balance of ${giftCardBalance.toFixed(2)}. We'll apply {total < giftCardBalance ? total.toFixed(2) : giftCardBalance.toFixed(2)} to your order.</div>
      }
    </div>
  )
}