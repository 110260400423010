import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export default function Footer() {

  return (
    <div>
      <footer>
        <div className='footerCopyright'>&copy; {new Date().getFullYear()} Asterism Books LLC</div>
        <div className='footerMenu'>
          <Link to='/about'>About Asterism</Link>
          <Link to='/meet-the-team'>Meet the Team</Link>
          <Link to='/customer-support'>Customer Support</Link>
          <Link to='/privacy'>Privacy Policy</Link>
          <Link to='/for-retailers'>Bookseller Application</Link>
          <Link to='/for-libraries'>Library Application</Link>
          <Link to='/careers'>Careers</Link>
          <Link to='https://feeds.asterismbooks.com'>Data Feeds</Link>
          <Link to='/tools/barcode-generator'>Barcode Generator</Link>
        </div>
      </footer>
    </div>
  )
}
