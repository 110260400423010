import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import ProductListItemSmall from '../ProductListItemSmall';
import './styles.css';
import { useRecoilValue } from 'recoil';
import GhostGrid from '../GhostGrid';
import { asaEvent, slugify } from '../../Utils';
import { pageviewState, sessionState } from '../../atoms';

export default function SidesScroller({ list = [], block, title, classList }) {
  const [display, setDisplay] = useState([]);
  const [start, setStart] = useState(0);
  const [per, setPer] = useState(6);
  const width = useWindowSize();
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);

  useEffect(() => {
    setPer(width > 2000 ? 8 : width > 1320 ? 6 : width > 900 ? 4 : width > 750 ? 2 : 1);
  }, [width])

  useEffect(() => {
    if (list) {
      setDisplay(list.slice(start, start + per));
    }
  }, [start, per, list])

  const container = useRef();

  const scrollRight = () => {
    if (list.length <= start + per) {
      setStart(0);
    } else {
      setStart(start + per);
    }
    pcEvent('scroll', 'scroll_right', 'button')
  }

  const scrollLeft = () => {
    if (start === 0) {
      setStart(list.length - per);
    } else if (start - per < 0) {
      setStart(0)
    } else {
      setStart(start - per);
    }
    pcEvent('scroll', 'scroll_left', 'button')
  }

  const pcEvent = (category, action, element) => {
    asaEvent({
      session: session.id,
      category: category,
      action: action,
      label: '',
      locationOne: `${block}__${slugify(title)}`,
      locationTwo: 'sidescroll_list',
      locationThree: element,
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
    });
  }

  let touchStartX = null;
  let touchStartY = null;

  return (
    <div className={`sideScrollWrapper ${classList}`}>
      <div className={`productListScroll-left ${list.length === per && 'behind'}`}>
        <button disabled={list.length === per} onClick={scrollLeft}><i class="fa-solid fa-chevron-left"></i></button>
      </div>

      <div className='productListSmaller'
        style={{ gridTemplateColumns: `repeat(${per.toString()},1fr)` }}
        ref={container}
        onTouchStart={(event) => {
          touchStartX = event.touches[0].clientX;
          touchStartY = event.touches[0].clientY;
        }}
        onTouchEnd={(event) => {
          if (touchStartX === null || touchStartY === null) {
            return;
          }

          const touchEndX = event.changedTouches[0].clientX;
          const touchEndY = event.changedTouches[0].clientY;

          const diffX = touchStartX - touchEndX;
          const diffY = touchStartY - touchEndY;

          if (Math.abs(diffX) > Math.abs(diffY)) {
            // Horizontal swipe
            if (diffX > 0) {
              scrollRight();
            } else {
              scrollLeft();
            }
          }

          touchStartX = null;
          touchStartY = null;
        }}
      >
        {display && display.map((product, index) => {
          return (
            <ProductListItemSmall product={product} context={{ block: block, title: title, index: index }} />
          )
        })}
        {display.length === 0 &&
          <GhostGrid items={per} />
        }
      </div>

      <div className={`productListScroll-right ${list.length === per && 'behind'}`}>
        <button disabled={list.length === per} onClick={scrollRight}><i class="fa-solid fa-chevron-right"></i></button>
      </div>
    </div>
  )
}


function useWindowSize() {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return width;
}